import React from "react"
import { useNavigate } from "react-router-dom";
import { MdOutlineSchool } from "react-icons/md";

import Button from "components/Button"
import imgBanner from "assets/imgs/hero.png"
import schoolHat from "assets/imgs/school-hat.png"
import { TROUVER_FORMATION_PATH } from "routes/navigation/navigationPaths";
import StateCard from "components/State/StateCard";

const Banner = () => {
    const navigate = useNavigate()
    return (
        <section className="m-h-[500px] bg-blue px-6">
            <div className="max-w-7xl m-auto md:grid lg:grid-cols-2 md:items-center gap-10 px-0 py-10">
                <div className="max-w-xl text-left relative mt-10 lg:mt-0">
                    <img src={schoolHat} alt="Chapeau" className="absolute top-[-90px] left-[-40px] object-cover" />
                    <h1 className="font-[800] text-[45px] sm:text-6xl leading-[70px] sm:leading-[4rem] text-white">
                        La plateforme qui vous aide à trouver votre formation
                    </h1>
                    <p className="mt-5 md:mt-4 md:block text-white text-lg font-light">
                        Avec Unistaf, trouvez les écoles habilitées et les diplômes accréditées au Sénégal !
                    </p>
                    <div className="flex flex-wrap gap-3 mt-10">
                        <Button
                            onClick={() => navigate(TROUVER_FORMATION_PATH)}
                            className="bg-white hover:opacity-90 text-blue text-center h-11 px-10"
                            title={"Trouver une formation"}
                            icon={{
                                name: MdOutlineSchool,
                                color: "#0D0DA3",
                                size: 25
                            }}
                        />
                    </div>
                </div>

                <div className="md:flex justify-center items-center relative">
                    <img
                        alt="La plateforme qui vous aide à trouver votre formation"
                        src={imgBanner}
                        className="hidden md:block w-full max-w-[550px] object-cover"
                    />
                    <div className="hidden md:block absolute bottom-20 right-[30px]">
                        <StateCard
                            number={500}
                            description="Choix de Formation"
                            icon={MdOutlineSchool}
                            iconBgColor="bg-orange"
                            borderColor="border-orange"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner