import React from "react"
import schooBlackHat from "assets/imgs/school-black-hat.png"

const SectionTitle = ({ title }) => {
    return (
        <div className="relative pt-10">
            <img
                src={schooBlackHat}
                alt="School hat"
                height={150}
                width={150}
                className="absolute top-[-50px] left-[90px]"
            />
            <h2
                className="text-2xl md:text-5xl text-center text-blackSecondary font-bold max-w-4xl mx-auto"
            >
                {title}
            </h2>
            {/* <div className="flex justify-center mt-3">
                <div className="border w-20 bg-blackSecondary"></div>
            </div> */}
        </div>
    )
}

export default SectionTitle