import React from "react";

const StateCard = ({ iconBgColor, borderColor, icon: Icon, number, description, flexDirection="flex-col" }) => {
  return (
    <div className={`flex gap-4 ${borderColor} border-2 bg-white rounded-2xl px-4 p-3 justify-center items-center`}>
      <div className={`${iconBgColor} rounded-lg w-10 h-10 flex justify-center items-center`}>
        <Icon color="#FFF" size={24} />
      </div>
      <div className={`flex ${flexDirection}`}>
        <p className="font-[800] text-md sm:text-2xl text-[#101828] m-0 p-0">{number}+</p>
        <p className="font-medium text-sm text-[#9e9f9f] m-0 p-0">{description}</p>
      </div>
    </div>
  )
}

export default StateCard