import React, { useState } from "react"
import DiplomeCardItem from "./DiplomeCardItem"
import { twMerge } from "tailwind-merge"
import FormationDetailsModal from "components/Formation/FormationDetailsModal";
import EmptyData from "components/EmptyData/EmptyData";
import { useDispatch, useSelector } from "react-redux";
import { useDiplomesList } from "./useDiplomesList";
import { setFormation } from "redux/features/trouverFormationSlice";

const DiplomeList = ({ className, isImage, diplomes }) => {
    const { trouverFormation } = useSelector((state) => state)
    const { selectedFormation, setSelectedFormation } = useDiplomesList()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            {openModal && <FormationDetailsModal selectedFormation={selectedFormation} openModal={openModal} closeModal={closeModal} />}
            <div className={twMerge(["flex gap-5", className])}>
                {
                    !diplomes || !diplomes.length ? <EmptyData
                        message={`Pas encore de formations disponible pour une formation dans le domaine de 
                                ${trouverFormation.selectedDomaine.libelle} en 
                                ${trouverFormation.selectedFiliere.libelle} pour le diplôme de ${trouverFormation.selectedDiplome.libelle}`} /> :
                        diplomes.map((diplome, index) => {
                            return <DiplomeCardItem
                                extraClasses="border-gray border rounded-2xl"
                                diplome={diplome}
                                onClick={() => {
                                // Pour utiliser la formation selectionnee a tout moment
                                dispatch(setFormation(diplome))
                                // Pour le modal
                                setSelectedFormation(diplome)
                                setOpenModal(true)
                            }} isImage={isImage} key={index} />
                    })
                }
            </div>
        </>
    )
}

export default DiplomeList