import React from "react"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { MdOutlineSchool } from "react-icons/md"
import { HiLocationMarker } from "react-icons/hi"
import { CiPhone, CiMail, CiGlobe } from "react-icons/ci";
import RenderWysiwyg from "render-wysiwyg";

import Modal from "layouts/modal/ModalComponent"
import blackLogo from "assets/logos/unistaf-black-logo.png"
import { DiplomeSpec } from "components/Diplomes/DiplomeCardItem"
import BadgeIcon from "components/Badge/BadgeIcon"
import Button from "components/Button"
import { ECOLES_PATH, REGISTER_PATH } from "routes/navigation/navigationPaths"

// const FormationTitle = styled.h3`
//   font-size: 25px;
//   font-weight: 600;
//   margin-top: 10px;
// `
const DescriptionTitle = styled.h4`
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
`
// const DescriptionText = styled.p`
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 23.3px;
// `

const Logo = () => {
  return (
    <Link className="block text-teal-600" href="/">
      <img src={blackLogo} alt="Unistaf" className="w-32" />
    </Link>
  )
}

const FormationDetailsModal = ({ selectedFormation, openModal, closeModal }) => {
  const navigate = useNavigate();
  return (
    <Modal title={"Détails sur la formation"} opened={openModal} onClosed={closeModal}>
      <div className="flex items-center gap-10 my-3">
        <Logo />
        <h3 className="text-xl font-semibold md:text-3xl md:font-bold">{selectedFormation.libelle}</h3>
      </div>
      <div className="mt-4">
        <div className="flex gap-10 mb-3">
          <DiplomeSpec label={"Domaine"} desc={selectedFormation.domaine.libelle} />
          <DiplomeSpec label={"Formation"} desc={selectedFormation.type} />
        </div>
        <div className="flex gap-10 mb-3">
          <DiplomeSpec label={"Accréditation"} desc={selectedFormation.accreditations} />
          <DiplomeSpec label={"Adresse"} desc={selectedFormation.ecole.adresse} />
        </div>
      </div>
      <div className="flex gap-2 mt-5">
        <BadgeIcon
          className={"bg-blueRgba text-blue text-xs"}
          icon={{
            name: MdOutlineSchool,
            size: 15
          }}
          label={selectedFormation.diplome.libelle}
        />
        <BadgeIcon
          className={"bg-blueRgba text-blue text-xs"}
          icon={{
            name: HiLocationMarker,
            size: 15,
            color: "red"
          }}
          label={selectedFormation.lieu}
        />
      </div>
      <div className="border-t border-gray mt-3">
        <DescriptionTitle>Description</DescriptionTitle>
        {/* <DescriptionText> */}
          <RenderWysiwyg content={selectedFormation.description} />
        {/* </DescriptionText> */}
      </div>
      <div className="mt-3">
        <div className="flex gap-2 mb-2">
          <CiPhone size={25} />
          <span>{selectedFormation.ecole.telephone}</span>
        </div>
        <div className="flex gap-2 mb-2">
          <CiMail size={25} />
          <span>{selectedFormation.ecole.email}</span>
        </div>
        <div className="flex gap-2 mb-2">
          <CiGlobe size={25} />
          <a href={selectedFormation.ecole.site_web} target="_blank" rel="noreferrer">{selectedFormation.ecole.site_web}</a>
        </div>
      </div>
      <div className="flex justify-center items-center flex-wrap gap-3 mt-4">
        <Button
          onClick={() => navigate(REGISTER_PATH)}
          title={"Candidater"}
          className="bg-blue text-white px-4 py-2 flex-1 min-w-[200px] hover:bg-[#003f96] hover:text-white"
        />
        <Button
          title={"Voir l’etablissement"}
          onClick={() => navigate(`${ECOLES_PATH}/${selectedFormation.ecole.slug}`)}
          className="flex-1 border px-4 py-2 min-w-fit hover:bg-[#003f96] hover:text-white"
        />
        {/* <div className="flex-1">
        </div>
        <div className="flex-1">
        </div> */}
      </div>
    </Modal>
  )
}

export default FormationDetailsModal