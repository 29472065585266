import React from "react"
import { BsBag } from "react-icons/bs"

const Domaine = () => {
  return (
    <div className="flex flex-col gap-2 flex-1 min-w-[200px] p-4 py-8 justify-center items-center border border-[#D9D9D9] rounded-xl">
      <BsBag color="#6E1D7C" size={24} />
      <p className="font-semibold text-[#333333] text-sm text-center">Commerce et Management</p>
    </div>
  )
}

export default Domaine