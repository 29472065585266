import React from "react"

import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import SectionTitle from "components/Section/SectionTitle"
import Domaine from "components/Domaine"

const DomaineDeFormation = () => {
  return (
    <AppMaxWidth>
      <SectionTitle title={"Plus de 500 formations dans différents domaines"} />
      <div className="flex gap-4 flex-wrap mt-8">
        {
          [1, 2, 3, 4, 5, 6, 7].map((index) => <Domaine key={index} />)
        }
      </div>
    </AppMaxWidth>
  )
}

export default DomaineDeFormation