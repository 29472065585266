import Button from "components/Button"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { BsArrowLeft } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { setDiplome, setDomaine, setFiliere, setLieuFormation } from "redux/features/trouverFormationSlice"

const TrouverMaFormationeContexte = createContext()

const TrouverMaFormationeContexteProvider = ({ children }) => {
  const [progressBar, setProgressBar] = useState(0)
  const { trouverFormation } = useSelector((state) => state)

  const [searchParams, setSearchParams] = useSearchParams();
  // TODO: utuliser Redux pour gerer le currentIndex
  // const [currentIndex, setCurrentIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(Number(localStorage.getItem("currentIndex")) ?? 0)

  const dispatch = useDispatch();

  const previousStep = () => {
    setCurrentIndex((prev) => prev - 1)
  }

  const nextStep = () => {
    setCurrentIndex((prev) => prev + 1)
  }

  function setParams(query, value) {
    searchParams.set(`${query}`, value)
    setSearchParams(searchParams)
  }

  const setDomaineChoice = (domaine) => {
    setParams("domaine", domaine.slug)
    dispatch(setDomaine(domaine))
    if (domaine.slug !== trouverFormation.selectedDomaine?.slug) {
      dispatch(setFiliere(null))
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }

  const setFiliereChoice = (filiere) => {
    setParams("filiere", filiere.slug)
    dispatch(setFiliere(filiere))
    if (filiere.slug !== trouverFormation.selectedFiliere?.slug) {
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }

  const setDiplomeChoice = (diplome) => {
    dispatch(setDiplome(diplome))
    if (diplome.slug !== trouverFormation.selectedDiplome?.slug) {
      dispatch(setLieuFormation(null))
    }
  }
  const setLieuFormationChoice = (lieu) => {
    setParams("lieu", lieu.libelle)
    dispatch(setLieuFormation(lieu))
  }

  const contextValue = useMemo(() => {
    return {
      previousStep,
      nextStep,
      setDomaineChoice,
      setFiliereChoice,
      setDiplomeChoice,
      setLieuFormationChoice
    }
  }, [])

  useEffect(() => {
    if (trouverFormation.selectedDomaine) {
      setParams("domaine", trouverFormation.selectedDomaine.slug)
    }
    if (trouverFormation.selectedFiliere) {
      setParams("filiere", trouverFormation.selectedFiliere.slug)
    }
    if (trouverFormation.selectedDiplome) {
      setParams("diplome", trouverFormation.selectedDiplome.slug)
    }
    if (trouverFormation.selectedLieuFormation) {
      setParams("lieu", trouverFormation.selectedLieuFormation.libelle)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("currentIndex", currentIndex)
    setProgressBar((currentIndex / (children.length - 1)) * 100)
  }, [currentIndex])

  useEffect(() => {
    if (currentIndex === 0) {
      setParams("domaine", "")
      setParams("filiere", "")
      setParams("diplome", "")
      setParams("lieu", "")
      dispatch(setDomaine(null))
      dispatch(setFiliere(null))
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }, [currentIndex])

  return (
    <TrouverMaFormationeContexte.Provider value={{ ...contextValue, trouverFormation }}>
      <div className="bg-white rounded-xl border border-[#D9D9D9]">
        <div className="flex gap-4">
          {
            React.Children.map(children, (_, index) => {
              return index > 0 && <div
                style={{ width: progressBar + "%", transition: "all 300ms" }}
                className={`h-[6px] ${currentIndex >= index ? "bg-blue" : "bg-gray"} mb-4 rounded-xl flex-1`}
              >
              </div>
            })
          }
        </div>
        {/* <div className="p-4 px-9">
          {
            Object.entries(trouverFormation).map((label, index) => {
              return !label[1] ? undefined : (label[1] && index > 0) ? <span key={index}> - {label[1].libelle}</span> : <span key={index}>{label[1].libelle}</span>
            })
          }
        </div> */}
        <div className="p-4 md:px-9">
          <div className="flex justify-end items-center gap-2">
            <Button
              onClick={previousStep}
              disabled={currentIndex==0}
              className="shadow-sm rounded-full text-sm border mb-3 p-2 px-4"
              title="Précédent"
              icon={{
                name: BsArrowLeft,
                position: "left"
              }}
            />
            {/* <Button
              className="shadow-sm rounded-full text-sm border mb-3 p-2 px-4"
              title="Suivant"
              icon={{
                name: BsArrowRight,
                position: "right"
              }}
            /> */}
          </div>

          {children[currentIndex]}
        </div>

      </div>
    </TrouverMaFormationeContexte.Provider>
  )
}

export default TrouverMaFormationeContexteProvider

export const useTrouverMaFormationeContexte = () => {
  const ctx = useContext(TrouverMaFormationeContexte)
  return ctx
}