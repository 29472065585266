import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useDebouncedCallback } from "use-debounce";

import Input from "components/Input"
import CursusScolaireProvider, { getCursusInitialValues } from "features/private/Candidatures/Candidature/CursusScolaire/CursusScolaireProvider"
import { useAddCursusMutation } from "actions/user/mutations";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import { addNewCursusForm, initializeCursusForm } from "redux/features/userslice";
import { useGetCurrentUserQuery } from "actions/auth/queries";
import Button from "components/Button";
import { MdAdd } from "react-icons/md";
import AnimateOnRender from "components/AnimateRender/AnimateOnRender";

const CursusFormRow = ({ addNew }) => {
  // const { register } = useFormContext()
  return <AnimateOnRender>
    <CursusRow addNew={addNew} />
  </AnimateOnRender>
}

const CursusRow = ({ addNew }) => {
  const { register, formState: { errors }, watch } = useFormContext()
  const { currentUser } = useSelector((state) => state.user);
  const { refetch: refetchCurrentUser } = useGetCurrentUserQuery()
  const dispatch = useDispatch()

  // TODO: la logique de l'ajout ou de la modification d'un cursus doit se faire dans un custom hook
  // TODO: Pour le moment, on est a la recherche de solution, donc la logique sera faite ici pour le moment
  const [addCursus] = useAddCursusMutation()
  const handleCursur = () => {
    const newCursus = {
      etablissement: watch("etablissement"),
      annee: watch("annee"),
      ville: watch("ville"),
      diplome: watch("diplome"),
      etudiant: currentUser.user.id
    }
    addCursus(newCursus).unwrap()
      .then((res) => {
        refetchCurrentUser().then((user) => {
          // On met a jour les cursus pour le formulaire
          dispatch(initializeCursusForm(user.data?.cursus))
        })
      }).catch((err) => {
        console.log({ err });
      })
  }
  const updateOrAddCursus = useDebouncedCallback(
    // function
    handleCursur,
    // delay in ms
    3000
  );

  useEffect(() => {
    if (addNew) {
      if (watch("etablissement") && watch("annee") && watch("ville") && watch("diplome")) {
        updateOrAddCursus()
      }
    }
  }, [watch("etablissement"), watch("annee"), watch("ville"), watch("diplome")])

  return <div className="flex flex-wrap gap-4 mt-4 border-b border-gray pb-3">
    <Input
      errorMessage={errors.etablissement?.message}
      register={{ ...register("etablissement") }}
      label="Etablissement"
      placeholder="Ajouter"
      inputContainerClass="flex-1"
    />
    <Input
      errorMessage={errors.annee?.message}
      register={{ ...register("annee") }}
      label="Année"
      placeholder="Ajouter"
      inputContainerClass="flex-1"
    />
    <Input
      errorMessage={errors.ville?.message}
      register={{ ...register("ville") }}
      label="Ville/Pays"
      placeholder="Ajouter"
      inputContainerClass="flex-1"
    />
    <Input
      errorMessage={errors.diplome?.message}
      register={{ ...register("diplome") }}
      label="Diplôme obtenu"
      placeholder="Ajouter"
      inputContainerClass="flex-1"
    />
  </div>
}

const CursusScolaireForm = () => {
  const { cursusForm } = useSelector((state) => state.user);
  useGetCurrentUser()
  const dispatch = useDispatch()

  return (
    <div>
      {/* <div className="flex flex-wrap"> */}
      {(cursusForm && cursusForm?.length) ?
        cursusForm.map((cursus, index) => {
          return <CursusScolaireProvider initialValues={cursus} key={index}>
            <CursusFormRow addNew={cursus?.new} />
          </CursusScolaireProvider>
        }) : <CursusScolaireProvider key={Math.random() * 100 + "mo"}>
          <CursusFormRow addNew={true} />
        </CursusScolaireProvider>
      }
      {/* </div> */}
      <Button
        title="Ajouter une formation"
        className="italic mt-6 border border-gray p-2 text-sm"
        onClick={() => {
          dispatch(addNewCursusForm({ ...getCursusInitialValues(), new: true }))
        }}
        icon={{
          name: MdAdd
        }}
      />
    </div>
  )
}

export default CursusScolaireForm