/* eslint-disable react/display-name */
import { useAddDocumentMutation, useUpdateDocumentMutation } from "actions/user/mutations"
import Button from "components/Button"
import Input from "components/Input"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import DocumentAFournirProvider from "features/private/Candidatures/Candidature/DocumentsAFournir/DocumentAFournirProvider"
import React, { memo, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { BsFilePdfFill } from "react-icons/bs"
import { CiEdit } from "react-icons/ci"
import { useDispatch, useSelector } from "react-redux"
import { updateDocumentForm } from "redux/features/userslice"

const DocumentRow = memo(({ document, index }) => {
  const [edit, setEdit] = useState(false);
  const { register, formState: { isDirty }, watch } = useFormContext()
  const [addNewDocument, { isLoading: isAdding }] = useAddDocumentMutation();
  const [updateDocument, { isLoading: isUpdationg }] = useUpdateDocumentMutation();
  const { currentUser } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    // ! isDirty nous permet de verifier si l'input a changer afin de ne pas declancher automatiquement les mise a jour api
    if (document?.slug && watch("fichier") && isDirty) {
      const formData = new FormData()
      formData.append("nom", document.nom)
      formData.append("fichier", watch("fichier")[0])
      formData.append("etudiant", currentUser.user.id)
      updateDocument({ body: formData, slug: document.slug }).unwrap()
        .then((res) => {
          dispatch(updateDocumentForm({ index, data: res }))
          setEdit(false)
        }).catch((err) => {
          console.log({ err });
        })
    } else if (watch("fichier") && isDirty) {
      // eslint-disable-next-line new-cap
      const formData = new FormData()
      formData.append("nom", document.nom)
      formData.append("fichier", watch("fichier")[0])
      formData.append("etudiant", currentUser.user.id)
      addNewDocument(formData).unwrap()
        .then((res) => {
          dispatch(updateDocumentForm({ index, data: res }))
        }).catch((err) => {
          console.log({ err });
        })
    }
  }, [watch("fichier")])

  return document.fichier ? <div>
    <p className="block mb-1 text-sm font-medium text-dark">{document.nom}</p>
    {!edit ? <div className="flex gap-2 items-center">
      <BsFilePdfFill color="red" size={40} />
      <CiEdit onClick={() => setEdit(true)} />
    </div> : isUpdationg ? <LoadingIndicator /> :
      <div className="flex flex-col gap-2">
        <Input accept={".pdf"} type="file" register={{ ...register("fichier") }} inputContainerClass="flex-1" />
        <Button onClick={() => setEdit(false)} title={"Annuler"} className="border" />
      </div>
    }
  </div> : isAdding ? <LoadingIndicator /> : <Input accept={".pdf"} type="file" label={document.nom} register={{ ...register("fichier") }} inputContainerClass="flex-1" />
})

const DocumentsAFournirForm = () => {
  const { documentsForm } = useSelector((state) => state.user);
  // console.log({ documentsForm });

  return (
    <div>
      <div className="flex flex-wrap gap-4">
        {
          documentsForm.map((document, index) => <div className="flex-1" key={index}>
            <DocumentAFournirProvider initialValues={{ fichier: document.fichier }}>
              <DocumentRow document={document} index={index} />
            </DocumentAFournirProvider>
          </div>)
        }
      </div>
    </div>
  )
}

export default DocumentsAFournirForm